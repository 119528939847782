<template>
  <vue-markdown
    :class="customClass"
    :watches="['show', 'html', 'breaks', 'linkify', 'emoji']"
    table-class="markdown-table"
    :source="source"
    :show="show"
    :html="html"
    :breaks="breaks"
    :linkify="linkify"
    :emoji="emoji"
    :typographer="typographer"
  >
    {{ source }}
  </vue-markdown>
</template>

<script>
import { mapGetters } from 'vuex'
import VueMarkdown from 'vue-markdown'
export default {
  name: 'Markdown',
  components: { VueMarkdown },
  props: {
    source: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: 'vue-markdown',
      required: false
    },
    show: {
      type: Boolean,
      default: true,
      required: false
    },
    html: {
      type: Boolean,
      default: true,
      required: false
    },
    breaks: {
      type: Boolean,
      default: true,
      required: false
    },
    linkify: {
      type: Boolean,
      default: true,
      required: false
    },
    emoji: {
      type: Boolean,
      default: true,
      required: false
    },
    typographer: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    ...mapGetters(['darkEnabled'])
  }
}
</script>

<style lang="scss">
.vue-markdown {
  text-align: left;
  padding: 10px;
  margin-top: 10px;
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.4em;
  }
  .markdown-table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: grey;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    thead th {
      border-bottom: solid 1px #ccc;
    }
  }
  img {
    width: 100%;
  }
}
</style>
