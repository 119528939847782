<template>
  <div class="page">
    <Panel lineColor="var(--primary-color)">
      <template v-if="isChecking" v-slot:options>
        <v-col class="justify-start" sm="6" md="3" lg="2" xl="2">
          <FormButton text="Voltar" type="default" full @click="onClose" />
        </v-col>
      </template>
      <v-timeline v-if="!isChecking" small>
        <v-timeline-item
          v-for="(item, i) in list"
          :key="i"
          :color="item.cor || 'green'"
          :icon="item.icone"
          fill-dot
        >
          <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold ${item.cor || 'green'}--text`"
            >
              {{ item.data_publicacao | formatDateTime(getTimeZone) }}
            </span>
          </template>

          <div class="py-4 card-novidades" @click="onCheck(item.idnovidade)">
            <h2
              :class="
                `headline font-weight-light mb-4 ${item.cor || 'green'}--text`
              "
            >
              {{ item.descricao }}
            </h2>
            <div class="resumo">
              {{ (item.resumo || '') + '...' }}
              <span style="color: #337ab7;">Ler mais</span>
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
      <div v-else>
        <b>
          <Markdown :source="currentObject.descricao" />
        </b>
        <br />
        <Markdown :source="currentObject.conteudo" />
      </div>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import Markdown from '@/components/Markdown'
import { mapGetters } from 'vuex'
import { NOVIDADES_LIST, NOVIDADES_LOAD } from '@/store/actions/novidades'

export default {
  name: 'NovidadesTable',
  components: {
    Panel,
    Markdown,
    FormButton
  },
  data: () => ({
    list: [],
    currentObject: null
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'getGatewaysOpcoes', 'getTimeZone']),
    isChecking() {
      return this.currentObject
    }
  },
  created() {
    const idnovidade = this.$route.query.idnovidade
    this.$store.dispatch(NOVIDADES_LIST).then(list => {
      this.list = list || []
      if (idnovidade) {
        this.onCheck(idnovidade)
      }
    })
  },
  methods: {
    getColor(index) {
      const colors = [
        'blue',
        'purple',
        'green',
        'orange',
        'yellow',
        'red',
        'black',
        'pink',
        'white'
      ]
      const length = colors.length
      const roundedValue = index / length
      const i = index < length ? index : Number(roundedValue)
      return colors[i]
    },
    onClose() {
      this.currentObject = null
    },
    onCheck(idnovidade) {
      this.$store.dispatch(NOVIDADES_LOAD, idnovidade).then(data => {
        this.currentObject = data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-novidades {
  cursor: pointer;
  .resumo {
    color: black !important;
    opacity: 0.7;
    text-align: left;
    padding: 0 10px;
  }
}
.card-novidades:hover {
  opacity: 0.7;
}
</style>
